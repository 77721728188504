import ComponentWithFloatingIconDownload from '@/components/ComponentWithFloatingIconDownload';

export const REMOTE_FACEBOOK_AD_CARD_DOWNLOAD_WRAPPER =
  'REMOTE_FACEBOOK_AD_CARD_DOWNLOAD_WRAPPER';

export default {
  name: 'RemoteFacebookAdCardDownloadWrapper',

  components: {
    ComponentWithFloatingIconDownload,
  },

  props: {
    post: {
      type: Object,
      required: true,
    },
    copyAd: {
      type: Function,
    },
  },
};
